import React, {FormEvent, useState} from "react"
import { useAppDispatch } from "../../store/hooks"
import { setDraftComments } from "../../store/callout/calloutDraft"

interface CalloutFormCommentsProps {
  maxLength?: number
  minLength?: number
  value?: string
}

const CalloutFormComments: React.FC<CalloutFormCommentsProps> = (props: CalloutFormCommentsProps) => {
  const maxLength = props.maxLength ?? 1000
  const minLength = props.minLength ?? null

  const dispatch = useAppDispatch()
  const [comments, setComments] = useState<string>(props.value ?? '')
  const [errors, setErrors] = useState<string[]>([])

  const validateAndDispatch = (event: FormEvent) => {
    let errors: string[] = []

    if(comments.length > maxLength) {
      errors.push(`Comments should less than ${maxLength} characters`)
    }

    if(minLength && comments.length < minLength) {
      errors.push(`Comments should be greater than ${minLength} characters`)
    }

    setErrors(errors)

    dispatch(setDraftComments(comments))
  }

  return (
    <div className="mb-3">
      <label 
        htmlFor="comments"
        className="text-sm font-bold"
      >
        Comments
      </label>
      <textarea 
        id="comments"
        className={`h-32 ${errors.length ? 'border-red-600' : ''}`}        
        value={comments}
        onChange={e => setComments(e.target.value)}
        onBlur={event => validateAndDispatch(event)}
      ></textarea>
      {
        errors.length 
        ? errors.map((error: string, index: number) => (
          <span 
            key={index}
            className="text-red-600"
          >
            {error}
          </span>
        ))
        : <></>
      }
    </div>
  )
}

export default CalloutFormComments