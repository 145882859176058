import adtBe from "../../config/adtBe.config";
import store from "../../store";
import { resetDraftCallout } from "../../store/callout/calloutDraft";

export const CalloutCreateRequest = () => {
  const draft = store.getState().calloutDraft.data;

  return new Promise((resolve, reject) => {
    adtBe
      .post("/callout/new", {
        site_name: draft.siteName,
        incident_reference: draft.incidentReference ?? null,
        alarms: draft.alarms,
        dispatched_at: draft.dispatchedAt,
        arrived_at: draft.arrivedAt,
        feedback: draft.feedback,
        batteries_stolen: draft.batteriesStolen,
        comments: draft.comments,
        closed: draft.closed,
      })
      .then((response) => {
        store.dispatch(resetDraftCallout());
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
