import React from "react";
import CalloutForm from "../../components/calloutForm/CalloutForm";
import { useAppSelector } from "../../store/hooks";

const CalloutEditView: React.FC = () => {
  const draftCallout = useAppSelector(state => state.calloutDraft.data)

  return (
    <div>
      <h1 className="font-bold text-2xl mb-5">Editing callout feedback #{draftCallout?.id}</h1>
      <CalloutForm mode="edit" callout={draftCallout} />
    </div>
  )
}

export default CalloutEditView;
