import adtBe from "../../config/adtBe.config";
import BreakIn from "../../models/BreakIn";
import store from "../../store";
import { clearDraft } from "../../store/breakIn/breakInDraft";
import { buildBreakInParams } from "./buildBreakInParams";

const BreakInCreateRequest = () => {
  const breakIn: BreakIn = store.getState().breakInDraft.data;

  const data = buildBreakInParams(breakIn);

  return adtBe.post("/break-in/new", data).then(() => {
    store.dispatch(clearDraft());
  });
};

export default BreakInCreateRequest;
