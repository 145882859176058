import React, { FormEvent, useEffect, useState } from "react";
import BreakInsShowRequest from "../../requests/BreakIns/BreakInsShowRequest";
import Loader from "../loader/Loader";

const BreakInSearchFilters: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [siteName, setSiteName] = useState<string>("");
  const [incidentId, setIncidentId] = useState<string>("");
  const [caseNumber, setCaseNumber] = useState<string>("");
  const [policeStation, setPoliceStation] = useState<string>("");

  const fetchBreakIns = async () => {
    setLoading(true);
    await BreakInsShowRequest({
      siteName,
      incidentId,
      caseNumber,
      policeStation,
    });
    setLoading(false);
  };

  const submit = (e: FormEvent) => {
    e.preventDefault();
    fetchBreakIns();
  };

  return (
    <form
      className="p-3 bg-gray-800 border border-gray-700 rounded my-5"
      onSubmit={submit}
    >
      <h3 className="font-bold pb-3 mb-3 text-xl border-b-4 border-gray-600">
        Search
      </h3>

      <div className="grid grid-cols-4 gap-5">
        <div>
          <label>Site name</label>
          <input
            type="text"
            onChange={(e) => setSiteName(e.target.value)}
            value={siteName}
            placeholder="Site name"
          />
        </div>
        <div>
          <label>Incident ID</label>
          <input
            type="text"
            onChange={(e) => setIncidentId(e.target.value)}
            value={incidentId}
            placeholder="Incident ID"
          />
        </div>
        <div>
          <label>Case number</label>
          <input
            type="text"
            onChange={(e) => setCaseNumber(e.target.value)}
            value={caseNumber}
            placeholder="Case Number"
          />
        </div>
        <div>
          <label>Police station</label>
          <input
            type="text"
            onChange={(e) => setPoliceStation(e.target.value)}
            value={policeStation}
            placeholder="Police Station"
          />
        </div>
      </div>
      <div className="mt-3 flex items-center">
        <button className="button mr-3">Search</button>
        {loading ? <Loader /> : <></>}
      </div>
    </form>
  );
};

export default BreakInSearchFilters;
