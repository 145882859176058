import { OpenCalloutOption } from "../../models/OpenCalloutOption";
import store from "../../store";
import {
  autoFillDraft,
  resetDraftCallout,
} from "../../store/callout/calloutDraft";

export const newCalloutLoader = (request: Request) => {
  store.dispatch(resetDraftCallout());
  let selectedCallout: OpenCalloutOption[] = [];
  const url = new URL(request.url);
  const incidentId = url.searchParams.get("incident");
  if (incidentId) {
    selectedCallout = store
      .getState()
      .openCalloutOptions.options.filter(
        (callout) => callout.incident_id === Number(incidentId)
      );
  }
  if (selectedCallout.length) {
    store.dispatch(autoFillDraft(selectedCallout[0]));
  }
  return selectedCallout;
};
