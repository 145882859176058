import adtBe from "../../config/adtBe.config";
import { BreakInSearchParams } from "../../models/BreakInSearchParams";
import store from "../../store";
import {
  setBreakInPagination,
  setBreakIns,
} from "../../store/breakIn/breakInsList";

interface BreakInsShowRequestParams {
  site_name?: string;
  incident_reference?: string;
  case_number?: string;
  police_station?: string;
  page?: number;
}

const BreakInsShowRequest = (params: BreakInSearchParams = {}) => {
  return adtBe
    .get("/break-ins", {
      params: buildOptionalParams(params),
    })
    .then((response) => {
      store.dispatch(setBreakIns(response.data.data));
      store.dispatch(setBreakInPagination(response.data.meta));
    });
};

const buildOptionalParams = (params: BreakInSearchParams) => {
  let options: BreakInsShowRequestParams = {};
  if (params.siteName) {
    options.site_name = params.siteName;
  }
  if (params.incidentId) {
    options.incident_reference = params.incidentId;
  }
  if (params.caseNumber) {
    options.case_number = params.caseNumber;
  }
  if (params.policeStation) {
    options.police_station = params.policeStation;
  }
  if (params.page) {
    options.page = params.page;
  }
  return options;
};

export default BreakInsShowRequest;
