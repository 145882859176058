import React, { ChangeEvent, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import Checkbox from "../shared/Checkbox";
import { setDraftEntryType } from "../../store/breakIn/breakInDraft";

interface EntryTypeProps {
  value?: string[];
}

const EntryTypeCheckbox: React.FC<EntryTypeProps> = (props) => {
  const dispatch = useAppDispatch();
  const [actions, setActions] = useState<string[]>(props.value ?? []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...actions];
    if (event.target.checked) {
      updatedList = [...actions, event.target.value];
    } else {
      updatedList = actions.filter((item) => item !== event.target.value);
    }
    setActions(updatedList);
    dispatch(setDraftEntryType(updatedList));
  };

  return (
    <div className="mb-5">
      <span className="text-sm font-bold mb-2 block">
        How did the intruder break in?
      </span>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
        <Checkbox
          checked={props.value?.includes("break fence") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Break Fence"
          id="break_fence"
          name="break_fence"
          value="break fence"
        />
        <Checkbox
          checked={props.value?.includes("jump fence") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Jump Fence"
          id="jump_fence"
          name="jump_fence"
          value="jump fence"
        />
        <Checkbox
          checked={props.value?.includes("tunnel under fence") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Tunnel Under Fence"
          id="tunnel_under_fence"
          name="tunnel_under_fence"
          value="tunnel under fence"
        />
        <Checkbox
          checked={props.value?.includes("no fence") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="No Fence"
          id="no_fence"
          name="no_fence"
          value="no fence"
        />
        <Checkbox
          checked={props.value?.includes("key access") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Key access"
          id="key_access"
          name="key_access"
          value="key access"
        />
        <Checkbox
          checked={props.value?.includes("other") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Other"
          id="other"
          name="other"
          value="other"
        />
      </div>
    </div>
  );
};

export default EntryTypeCheckbox;
