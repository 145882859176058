import { createSlice } from "@reduxjs/toolkit";
import { Callout } from "../../models/Callout";
import { Paginator } from "../../models/Paginator";
import BreakIn from "../../models/BreakIn";

interface BreakinListState {
  data: BreakIn[];
  pagination: Paginator | null;
  requestError: string | null;
}

const initialState: BreakinListState = {
  data: [],
  pagination: null,
  requestError: null,
};

const breakInsList = createSlice({
  name: "breakInsList",
  initialState,
  reducers: {
    setBreakIns: (state, action: { payload: BreakIn[] }) => {
      state.data = action.payload;
    },
    setBreakInPagination: (state, action: { payload: Paginator }) => {
      state.pagination = action.payload;
    },
  },
});

export const { setBreakIns, setBreakInPagination } = breakInsList.actions;

export default breakInsList.reducer;
