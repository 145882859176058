import React, { ChangeEvent, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import Checkbox from "../shared/Checkbox";
import { setDraftCriminalAction } from "../../store/breakIn/breakInDraft";

interface CriminalActionProps {
  value?: string[];
}

const CriminalActionCheckbox: React.FC<CriminalActionProps> = (props) => {
  const dispatch = useAppDispatch();
  const [actions, setActions] = useState<string[]>(props.value ?? []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...actions];
    if (event.target.checked) {
      updatedList = [...actions, event.target.value];
    } else {
      updatedList = actions.filter((item) => item !== event.target.value);
    }
    setActions(updatedList);
    dispatch(setDraftCriminalAction(updatedList));
  };

  return (
    <div className="mb-5">
      <span className="text-sm font-bold mb-2 block">
        What happened on site?
      </span>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
        <Checkbox
          checked={props.value?.includes("battery theft") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Battery Theft"
          id="battery_theft"
          name="crimanal_action"
          value="battery theft"
        />
        <Checkbox
          checked={props.value?.includes("attempted battery theft") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Attempted Battery Theft"
          id="attempted_battery_theft"
          name="crimanal_action"
          value="attempted battery theft"
        />
        <Checkbox
          checked={props.value?.includes("vandalism") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Vandalism"
          id="vandalism"
          name="crimanal_action"
          value="vandalism"
        />
      </div>
    </div>
  );
};

export default CriminalActionCheckbox;
