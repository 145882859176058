import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import DashboardView from "../views/DashboardView";
import CalloutCreateView from "../views/callout/CalloutCreateView";
import NoMatchView from "../views/NoMatchView";
import LoginView from "../views/auth/LoginView";
import GuestLayout from "../layouts/GuestLayout";
import CalloutEditView from "../views/callout/CalloutEditView";
import RecentlyClosedView from "../views/callout/RecentlyClosedView";
import BreakInReportCreateView from "../views/breakIn/BreakInReportCreateView";
import BreakInReportEditView from "../views/breakIn/BreakInReportEditView";
import { newCalloutLoader } from "./loaders/newCalloutLoader";
import { editCalloutLoader } from "./loaders/editCalloutLoader";
import { newBreakInLoader } from "./loaders/newBreakInLoader";
import BreakInsListView from "../views/breakIn/BreakInsListView";
import { editBreakInLoader } from "./loaders/editBreakInLoader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <DashboardView />,
      },
      {
        path: "callout",
        children: [
          {
            path: "new",
            loader: ({ request }) => newCalloutLoader(request),
            element: <CalloutCreateView />,
          },
          {
            path: ":id",
            element: <CalloutEditView />,
            loader: ({ params }) => editCalloutLoader(params),
          },
        ],
      },
      {
        path: "recent-feedback",
        element: <RecentlyClosedView />,
      },
      {
        path: "break-in-reports",
        element: <BreakInsListView />,
      },
      {
        path: "break-in-report",
        children: [
          {
            path: "new",
            element: <BreakInReportCreateView />,
            loader: ({ request }) => newBreakInLoader(request),
          },
          {
            path: ":id",
            element: <BreakInReportEditView />,
            loader: ({ params }) => editBreakInLoader(params),
          },
        ],
      },
      {
        path: "*",
        element: <NoMatchView />,
      },
    ],
  },
  {
    path: "/auth",
    element: <GuestLayout />,
    children: [
      {
        path: "login",
        element: <LoginView />,
      },
    ],
  },
]);

export default router;
