import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setDraftSiteName } from '../../store/callout/calloutDraft'

interface SiteNameProps {
  value?: string
}

const CalloutFormSiteName: React.FC<SiteNameProps> = (props: SiteNameProps) => {
  const dispatch = useAppDispatch()
  const [siteName, setSiteName] = useState<string>(props.value ?? '')

  const dispatchSiteName = () => {
    dispatch(setDraftSiteName(siteName))
  }

  return (
    <div className="mb-3">
      <label 
        htmlFor="siteName"
        className="text-sm font-bold"
      >
        Site Name*
      </label>
      <div className="relative">
        <input 
          id="siteName"
          onChange={e => {
            setSiteName(e.target.value);
          }}
          onBlur={() => dispatchSiteName()}
          value={siteName}
          placeholder='Site Name'
          disabled={props.value ? true : false}
        />
      </div>
    </div>
  )
}

export default CalloutFormSiteName