import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/msal.config";
import { toast } from "react-toastify";
import { setMSToken, setEmail } from "../../store/auth/auth";
import { useAppDispatch } from "../../store/hooks";
import Loader from "../loader/Loader";
import { AuthTokenCreateRequest } from "../../requests/AuthTokenCreateRequest";

export const SignInButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { instance } = useMsal();

  const handleLogin = () => {
    setIsLoading(true)
    instance.loginPopup(loginRequest)
    .then(async (response) => {
      dispatch(setMSToken(response.accessToken))
      if(response.account?.username){
        dispatch(setEmail(response.account.username))

        await AuthTokenCreateRequest({email: response.account.username.toLowerCase()})
        setIsLoading(false)
      }
      toast.success('Logged in.')
    })
    .catch(() => {
      setIsLoading(false)
      toast.error('There was a problem logging in.')
    });
  }
  return (
      <button  
        onClick={() => handleLogin()}
        className="button w-full flex items-center justify-center"
      >
        {
          isLoading 
          ? <Loader color="#0F172A" className="mr-2 mt-1"/>
          : <></>
        }
        Sign in with MS Azure
      </button>
  );
}