import React from "react";
import dayjs from "dayjs";
import { Callout } from "../../models/Callout";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { autoFillDraft } from "../../store/callout/calloutDraft";

interface CalloutsListItemProps {
  callout: Callout;
}

const CalloutsListItem: React.FC<CalloutsListItemProps> = (
  props: CalloutsListItemProps
) => {
  const date = dayjs(props.callout.createdAt);
  const dispatch = useAppDispatch();

  return (
    <div className="border border-gray-700 bg-gray-800 my-5 p-3 rounded">
      <div className="flex justify-between lg:items-center flex-col lg:flex-row">
        <div className="flex justify-between lg:items-center flex-col lg:flex-row">
          <span className="mr-3 text-sm mb-3 lg:mb-0">
            #{props.callout.incidentReference}
          </span>
          <span className="break-words mb-3 lg:mb-0 inline-block w-full">
            <span className="mr-1 font-bold block lg:inline-block">
              Site Name:
            </span>
            {props.callout.siteName}
          </span>
        </div>
        <div className="flex justify-end lg:items-center flex-col lg:flex-row">
          <span className="mr-3 mb-3 lg:mb-0">
            <span className="mr-1 font-bold block lg:inline-block">
              Reported at:
            </span>
            {date.format("hh:mm A")} on {date.format("D/M/YY")}
          </span>
          <div className="flex items-center">
            <Link
              to={`/callout/${props.callout.id}`}
              className="button py-2 inline-block w-32 text-center mr-5"
            >
              Edit
            </Link>

            <Link
              to={`/break-in-report/new?incident=${props.callout.incidentReference}&site_name=${props.callout.siteName}`}
              className="button py-2 inline-block text-center"
            >
              Report Break In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalloutsListItem;
