import BreakIn from "../../models/BreakIn";

export const buildBreakInParams = (breakin: BreakIn) => {
  let data = {};

  if (breakin.incidentId) {
    data = { ...data, incident_id: breakin.incidentId };
  }

  if (breakin.siteName) {
    data = { ...data, site_name: breakin.siteName };
  }

  if (breakin.landlord) {
    data = { ...data, landlord: breakin.landlord };
  }

  if (breakin.lockType) {
    data = { ...data, locktype: breakin.lockType };
  }

  if (breakin.incidentTime) {
    data = { ...data, occurred_at: breakin.incidentTime };
  }

  if (breakin.policeStation) {
    data = { ...data, police_station: breakin.policeStation };
  }

  if (breakin.caseNumber) {
    data = { ...data, case_number: breakin.caseNumber };
  }

  if (breakin.entryType) {
    data = { ...data, intrusion_methods: breakin.entryType };
  }

  if (breakin.criminalAction) {
    data = { ...data, crimes: breakin.criminalAction };
  }

  if (breakin.itemsStolen) {
    data = { ...data, stolen_items: breakin.itemsStolen };
  }

  return data;
};
