import React from "react";
import { useAppSelector } from "../../store/hooks";
import BreakInsShowRequest from "../../requests/BreakIns/BreakInsShowRequest";

const BreakInListPaginator: React.FC = () => {
  const currentPage = useAppSelector(
    (state) => state.breakInsList.pagination?.current_page
  );
  const lastPage = useAppSelector(
    (state) => state.breakInsList.pagination?.last_page
  );

  const nextPage = () => {
    BreakInsShowRequest({ page: currentPage! + 1 });
  };

  const previousPage = () => {
    BreakInsShowRequest({ page: currentPage! - 1 });
  };

  return (
    <ul className="flex justify-center">
      {currentPage !== 1 && (
        <li
          className="mx-3 hover:cursor-pointer text-blue-500"
          onClick={() => previousPage()}
        >
          Previous
        </li>
      )}

      <li className="mx-3 text-gray-300">{currentPage}</li>

      {currentPage !== lastPage && (
        <li
          className="mx-3 hover:cursor-pointer text-blue-500"
          onClick={() => nextPage()}
        >
          Next
        </li>
      )}
    </ul>
  );
};

export default BreakInListPaginator;
