import { Params } from "react-router";
import { Callout } from "../../models/Callout";
import store from "../../store";
import {
  resetDraftCallout,
  setDraftCallout,
} from "../../store/callout/calloutDraft";

export const editCalloutLoader = (params: Params) => {
  store.dispatch(resetDraftCallout());
  const draftCallout: Callout[] = store
    .getState()
    .calloutsList.data.filter((callout) => callout.id == params.id);
  store.dispatch(setDraftCallout(draftCallout[0]));
  return draftCallout;
};
