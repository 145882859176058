import React from "react";
import BreakInReportForm from "../../components/breakInReport/BreakInReportForm";

const BreakInReportEditView: React.FC = () => {
  return (
    <div>
      <BreakInReportForm mode="edit" />
    </div>
  );
};

export default BreakInReportEditView;
