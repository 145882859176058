import { createSlice } from "@reduxjs/toolkit";
import { Callout } from "../../models/Callout";
import { Paginator } from "../../models/Paginator";
import { CalloutsSearchParams } from "../../models/CalloutSearchParams";

interface calloutListState {
  data: Callout[];
  pagination: Paginator | null;
  searchFilters: CalloutsSearchParams;
  requestError: string | null;
}

const initialState: calloutListState = {
  data: [],
  pagination: null,
  requestError: null,
  searchFilters: {},
};

const calloutsList = createSlice({
  name: "calloutsList",
  initialState,
  reducers: {
    setCallouts: (state, action: { payload: Callout[] }) => {
      state.data = action.payload;
    },
    setCalloutsPagination: (state, action: { payload: Paginator }) => {
      state.pagination = action.payload;
    },
    setRequestError: (state, action: { payload: string }) => {
      state.requestError = action.payload;
    },
    nextPage: (state) => {
      if (state.pagination?.current_page) {
        state.pagination.current_page++;
      }
    },
    previousPage: (state) => {
      if (state.pagination?.current_page) {
        state.pagination.current_page--;
      }
    },
    setSiteNameFilter: (state, action: { payload: string }) => {
      state.searchFilters.siteName = action.payload;
    },
    setIncidentIdFilter: (state, action: { payload: string }) => {
      state.searchFilters.incidentId = action.payload;
    },
  },
});

export const {
  setCallouts,
  setCalloutsPagination,
  setRequestError,
  nextPage,
  previousPage,
  setSiteNameFilter,
  setIncidentIdFilter,
} = calloutsList.actions;

export default calloutsList.reducer;
