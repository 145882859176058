import axios from "axios";
import store from "../../store";
import { syncOpenCallouts } from "../../store/callout/openCalloutOptions";
import { createHeaders } from "../../config/nexus.config";

const OpenCalloutOptionsRequest = () => {
  const headers = createHeaders();

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_OPEN_CALLOUTS_ENDPOINT}select_security_incident_v2.php`,
        headers
      )
      .then((response) => {
        store.dispatch(syncOpenCallouts(response.data?.incident));
        resolve(response);
      })
      .catch(() => {
        reject();
      });
  });
};

export default OpenCalloutOptionsRequest;
