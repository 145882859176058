import React from "react";
import BreakInReportForm from "../../components/breakInReport/BreakInReportForm";

const BreakInReportCreateView: React.FC = () => {
  return (
    <div>
      <BreakInReportForm mode="create" />
    </div>
  );
};

export default BreakInReportCreateView;
