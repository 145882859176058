import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDraftCaseNumber,
  setDraftErrorCaseNumber,
} from "../../store/breakIn/breakInDraft";

interface PoliceCaseNumberInputProps {
  value?: string;
  onChange?: Function;
}

const PoliceCaseNumberInput: React.FC<PoliceCaseNumberInputProps> = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(props.value ?? "");
  const errors = useAppSelector(
    (state) => state.breakInDraft.errors.caseNumber
  );

  useEffect(() => {
    dispatch(setDraftCaseNumber(value));
  });

  return (
    <div>
      <label htmlFor="case_number" className="font-bold text-sm">
        Police Case Number
      </label>
      <input
        id="case_number"
        name="case_number"
        placeholder="Police Case Number"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {errors.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default PoliceCaseNumberInput;
