import React from "react"
import './loader.css'

interface LoaderProps {
  width?: number
  height?: number
  color?: string
  border?: number
  className?: string
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const width = props.width ?? 24
  const height = props.height ?? 24
  const border = props.border ?? 4
  const color = props.color ?? '#fff'

  const size = {
    width: `${width}px`,
    height: `${height}px`
  }

  const loaderStyle = {
    border: `${border}px solid ${color}`,
    borderColor: `${color} transparent transparent transparent`
  }

  return (
    <div 
      className={`lds-ring ${props.className ?? null}`}
      style={size}
    >
      <div style={loaderStyle}></div>
      <div style={loaderStyle}></div>
      <div style={loaderStyle}></div>
      <div style={loaderStyle}></div>
    </div>
  )
}

export default Loader