import React from "react";
import Logo from "../icons/Logo";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { open } from "../../store/menu/menu";
import MenuIcon from "../icons/MenuIcon";

const MainNavigation: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.menu.isOpen);

  const openMenu = () => {
    dispatch(open());
  };

  return (
    <nav className="border-b border-gray-700 bg-gray-800 h-16 flex items-center">
      <span className="px-3" onClick={() => openMenu()}>
        <MenuIcon />
      </span>
      <div className="text-white flex justify-between items-center px-5">
        <Link to={"/"} className="flex items-center">
          <Logo width={40} height={40} className="mr-3" />
          <span className="font-bold text-2xl">Callout Reporter</span>
        </Link>
      </div>
    </nav>
  );
};

export default MainNavigation;
