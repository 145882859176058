import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import CalloutsShowRequest from "../../requests/Callouts/CalloutsShowRequest";
import CalloutsListItem from "./CalloutsListItem";
import { Callout } from "../../models/Callout";
import Loader from "../loader/Loader";
import CalloutsListPaginator from "./CalloutsListPaginator";
import CalloutsListFilters from "./CalloutsListFilters";

const CalloutsList: React.FC = () => {
  const callouts: Callout[] = useAppSelector<Callout[]>(
    (state) => state.calloutsList.data
  );
  const error = useAppSelector((state) => state.calloutsList.requestError);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!error && !isLoading) {
      setIsLoading(true);
      CalloutsShowRequest()
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <div>
      <CalloutsListFilters />

      {isLoading ? (
        <div className="my-3 flex justify-center">
          <Loader width={50} height={50} />
        </div>
      ) : (
        <></>
      )}

      {!isLoading && !callouts.length ? (
        <div className="py-2">
          <div className="bg-gray-800 border-gray-600 border p-5 rounded">
            No results found.
          </div>
        </div>
      ) : (
        callouts.map((callout: Callout) => (
          <CalloutsListItem key={callout.id} callout={callout} />
        ))
      )}

      {callouts.length ? <CalloutsListPaginator /> : <></>}
    </div>
  );
};

export default CalloutsList;
