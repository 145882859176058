import { FormOption } from "../models/FormOption";

export const responseFeedbackOptions: FormOption[] = [
  {
    label: 'All in order',
    value: 'all in order',
  },
  {
    label: 'Not in order',
    value: 'not in order',
  }
]

export const followUpAssignees: FormOption[] = [
  {
    label: 'FSE Team',
    value: 'fse_team',
  },
]