import React, { useEffect, useState } from "react";
import OpenCalloutOptionsRequest from "../../requests/OpenCallouts/OpenCalloutOptionRequest";
import Loader from "../loader/Loader";
import { useAppSelector } from "../../store/hooks";
import { OpenCalloutOption } from "../../models/OpenCalloutOption";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OpenCalloutListItem from "./OpenCalloutListItem";

const OpenCalloutList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openCallouts: OpenCalloutOption[] = useAppSelector(
    (state) => state.openCalloutOptions.options
  );
  const repeatOptions = {
    shouldRepeat: true,
    delay: 0,
  };

  const fetchCallouts = async () => {
    setIsLoading(true);
    await OpenCalloutOptionsRequest();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!openCallouts.length && !isLoading) {
      fetchCallouts();
    }
  }, [openCallouts]);

  const renderTime = ({ remainingTime }: { remainingTime: number }) => {
    return (
      <div className="text-center">
        <div className="text-blue-500 text-lg">{remainingTime}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="absolute right-5 top-5 bg-gray-900 rounded-full">
        <CountdownCircleTimer
          isPlaying
          duration={60}
          size={50}
          strokeWidth={5}
          colors={"#3b82f6"}
          onComplete={() => {
            fetchCallouts();
            return repeatOptions;
          }}
        >
          {renderTime}
        </CountdownCircleTimer>
        {isLoading ? <Loader width={40} height={40} className="my-3" /> : <></>}
      </div>

      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {openCallouts.map((callout: OpenCalloutOption) => (
          <li key={callout.incident_id}>
            <OpenCalloutListItem callout={callout} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OpenCalloutList;
