import { resolve } from "path";
import adtBe from "../../config/adtBe.config";

const SiteNameRequest = (query: string) => {
  return new Promise<any>((resolve, reject) => {
    adtBe
      .get(`/autocomplete/site-name/${query}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default SiteNameRequest;
