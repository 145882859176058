import React from "react"

interface LogoProps {
  width?: number,
  height?: number, 
  className?: string,
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" className={`fill-current ${props.className}`}>
      <path d="M12 0c-2.996 2.995-7.486 4-11 4 0 8.583 5.067 16.097 11 20 5.932-3.903 11-11.417 11-20-3.515 0-8.006-1.005-11-4zm-.587 14.953l-3.452-3.362 1.237-1.239 2.215 2.123 4.381-4.475 1.238 1.238-5.619 5.715z"/>
    </svg>
  )
}

export default Logo