import React from "react"
import OpenCalloutList from "../components/openCalloutList/OpenCalloutList"

const DashboardView: React.FC = () => {
  return (
    <div>
      <h1 className="font-bold text-2xl mb-5">Open callouts</h1>
      <OpenCalloutList />
    </div>
  )
}

export default DashboardView