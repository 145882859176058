import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDraftErrorPoliceStation,
  setDraftPoliceStation,
} from "../../store/breakIn/breakInDraft";

interface PoliceStationInputProps {
  value?: string;
  onChange?: Function;
}

const PoliceStationInput: React.FC<PoliceStationInputProps> = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(props.value ?? "");
  const errors = useAppSelector(
    (state) => state.breakInDraft.errors.policeStation
  );

  useEffect(() => {
    let validationErrors: string[] = [];
    if (value.length > 0 && value.length < 2) {
      validationErrors.push(
        "Case number should be at least 2 characters in length."
      );
    }
    dispatch(setDraftErrorPoliceStation(validationErrors));
  }, [value]);

  useEffect(() => {
    dispatch(setDraftPoliceStation(value));
  });

  return (
    <div>
      <label htmlFor="police_station" className="font-bold text-sm">
        Police Station
      </label>
      <input
        id="police_station"
        name="police_station"
        placeholder="Police Station"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {errors.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default PoliceStationInput;
