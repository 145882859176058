import React, { useEffect, useState } from "react";
import DateTimePicker from "../shared/DateTimePicker";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDraftArrivedAt,
  setDraftArrivedAtErrors,
} from "../../store/callout/calloutDraft";

interface ArrivedAtProps {
  value?: string;
  onError?: Function;
}

const CalloutFormArrivedAt: React.FC<ArrivedAtProps> = (
  props: ArrivedAtProps
) => {
  const dispatch = useAppDispatch();
  const [arrivedAt, setArrivedAt] = useState<string>(props.value ?? "");
  const [errors, setErrors] = useState<string[]>([]);
  const dispatchedAt = useAppSelector(
    (state) => state.calloutDraft.data.dispatchedAt
  );

  useEffect(() => {
    if (errors.length && props.onError) {
      props.onError(errors[0]);
    }
    dispatch(setDraftArrivedAtErrors(errors));
  }, [errors]);

  useEffect(() => {
    dispatch(setDraftArrivedAt(arrivedAt));
  }, [arrivedAt]);

  useEffect(() => {
    let currentErrors: string[] = [];
    if (new Date(dispatchedAt) > new Date(arrivedAt)) {
      currentErrors.push(
        'The "arrived at" date can\'t be older than the "dispatched at" date.'
      );
    }
    setErrors(currentErrors);
  }, [arrivedAt]);

  return (
    <div>
      <label htmlFor="arrivedAt" className="text-sm font-bold">
        Arrived at
      </label>
      <DateTimePicker
        onChange={(value: string) => setArrivedAt(value)}
        value={arrivedAt}
        hasError={Boolean(errors.length)}
      />
      {errors.map((error, index) => (
        <span key={index} className="text-sm text-red-500">
          {error}
        </span>
      ))}
    </div>
  );
};

export default CalloutFormArrivedAt;
