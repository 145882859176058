import React, { useEffect, useState } from "react"
import dayjs from "dayjs"

export interface DateTimePickerProps {
  value: string
  onChange: Function
  disabled?: boolean
  hasError?: boolean
}

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [date, setDate] = useState(props.value ? dayjs(props.value).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'))
  const [time, setTime] = useState(props.value ? dayjs(props.value).format('HH:mm') : dayjs().format('HH:mm'))

  useEffect(() => {
    props.onChange(dayjs(`${date} ${time}`).format('YYYY-MM-DD HH:mm'))
  }, [date, time])

  return (
    <div className={`datetimepicker ${isFocused ? 'outline-blue-600 outline' : null} ${props.disabled ? 'disabled' : ''} ${props.hasError ? 'border-red-500': ''}`}>
      <div>
        <input 
          type="date"
          className="focus:outline-none border-0 text-white"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={e => {setDate(e.target.value)}}
          value={date}
          disabled={props.disabled ?? false}
        />
      </div>
      <div>
        <input 
          type="time"
          className="focus:outline-none border-0 text-white"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={e => {setTime(e.target.value)}}
          value={time}
          disabled={props.disabled ?? false}
        />
      </div>
    </div>
  )
}

export default DateTimePicker
