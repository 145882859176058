import React from "react";
import { Link } from "react-router-dom";
import { SignOutButton } from "../auth/SignOutButton";
import Logo from "../icons/Logo";
import { useLocation } from "react-router";
import "./SideNavigation.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { close } from "../../store/menu/menu";
import CloseIcon from "../icons/CloseIcon";

const SideNavigation: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isOpen = useAppSelector((state) => state.menu.isOpen);

  const closeMenu = () => {
    dispatch(close());
  };

  return (
    <>
      {isOpen ? (
        <div className="maskLayer" onClick={() => closeMenu()}></div>
      ) : (
        <></>
      )}

      <div className={`nav-container ${isOpen ? "open" : "closed"}`}>
        <nav className="relative p-3">
          {isOpen ? (
            <span className="absolute right-[-1rem] top-0 p-2 mt-3 border border-gray-700 rounded-full bg-gray-900">
              <CloseIcon onClick={() => closeMenu()} />
            </span>
          ) : (
            <></>
          )}

          <div className="border-b border-gray-600">
            <Link to={"/"} className="my-3 flex items-center">
              <Logo width={30} height={30} className="mr-3" />
              <span className="font-bold text-2xl">Callout Reporter</span>
            </Link>
          </div>
          <ul>
            <li>
              <Link
                to="/"
                className={`menu-link ${location.pathname === "/" && "active"}`}
                onClick={() => closeMenu()}
              >
                Open Callouts
              </Link>
            </li>
            <li>
              <Link
                to="/recent-feedback"
                className={`menu-link  ${
                  location.pathname === "/recent-feedback" && "active"
                }`}
                onClick={() => closeMenu()}
              >
                Recent Feedback
              </Link>
            </li>
            <li>
              <span className="menu-link parent">Break Ins</span>
              <ul>
                <li>
                  <Link
                    to="/break-in-report/new"
                    className={`menu-link child ${
                      location.pathname === "/break-in-report" && "active"
                    }`}
                    onClick={() => closeMenu()}
                  >
                    Report a break in
                  </Link>
                </li>
                <li>
                  <Link
                    to="/break-in-reports"
                    className={`menu-link child ${
                      location.pathname === "/break-in-reports" && "active"
                    }`}
                    onClick={() => closeMenu()}
                  >
                    Break In History
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mt-3">
              <SignOutButton />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SideNavigation;
