import adtBe from "../../config/adtBe.config";
import { CalloutsSearchParams } from "../../models/CalloutSearchParams";
import store from "../../store";
import {
  setCallouts,
  setCalloutsPagination,
  setRequestError,
} from "../../store/callout/calloutsList";

interface CalloutShowRequestParams {
  site_name?: string;
  incident_reference?: string;
  page?: number;
}

const CalloutsShowRequest = (params: CalloutsSearchParams = {}) => {
  return new Promise((resolve, reject) => {
    adtBe
      .get("/callouts", {
        params: buildOptionalParams(params),
      })
      .then((response) => {
        store.dispatch(setCallouts(response.data?.data));
        store.dispatch(setCalloutsPagination(response.data?.meta));
        resolve(response);
      })
      .catch((error) => {
        store.dispatch(setRequestError(error.message));
        reject(error);
      });
  });
};

const buildOptionalParams = (params: CalloutsSearchParams) => {
  let options: CalloutShowRequestParams = {};
  if (params.siteName) {
    options.site_name = params.siteName;
  }
  if (params.incidentId) {
    options.incident_reference = params.incidentId;
  }
  if (params.page) {
    options.page = params.page;
  }
  return options;
};

export default CalloutsShowRequest;
