import React, { ChangeEvent, useState } from "react"
import { useAppDispatch } from "../../store/hooks"
import { setDraftAlarms } from "../../store/callout/calloutDraft"
import Checkbox from "../shared/Checkbox"

interface AlarmsProps {
  value?: string[]
}

const CalloutFormAlarms: React.FC<AlarmsProps> = (props: AlarmsProps) => {
  const dispatch = useAppDispatch()
  const [alarms, setAlarms] = useState<string[]>(props.value ?? ['door'])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...alarms];
    if (event.target.checked) {
      updatedList = [...alarms, event.target.value];
    } else {
      updatedList = alarms.filter((item) => item !== event.target.value)
    }
    setAlarms(updatedList);
    dispatch(setDraftAlarms(updatedList))
  }

  return (
    <div className="mb-3">
      <span className="text-sm font-bold mb-2 block">Alarms</span>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
        <Checkbox 
          checked={props.value?.includes('vibration') ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Vibration"
          id="vibrationAlarm"
          name="alarms"
          value="vibration"
          disabled={props.value ? true : false}
        />
        <Checkbox 
          checked={props.value?.includes('door') ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Door open"
          id="doorOpenAlarm"
          name="alarms"
          value="door"
          disabled={props.value ? true : false}
        />
        <Checkbox 
          checked={props.value?.includes('camera') ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Camera"
          id="cameraAlarm"
          name="alarms"
          value="camera"
          disabled={props.value ? true : false}
        /> 
        <Checkbox 
          checked={props.value?.includes('battery_lost') ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Battery Lost"
          id="batteryLost"
          name="alarms"
          value="battery_lost"
          disabled={props.value ? true : false}
        />       
      </div>
    </div>
  )
}

export default CalloutFormAlarms