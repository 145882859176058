import React, { ChangeEvent, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import Checkbox from "../shared/Checkbox";
import { setDraftItemsStolen } from "../../store/breakIn/breakInDraft";

interface ItemsStolenProps {
  value?: string[];
}

const ItemsStolenCheckbox: React.FC<ItemsStolenProps> = (props) => {
  const dispatch = useAppDispatch();
  const [actions, setActions] = useState<string[]>(props.value ?? []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...actions];
    if (event.target.checked) {
      updatedList = [...actions, event.target.value];
    } else {
      updatedList = actions.filter((item) => item !== event.target.value);
    }
    setActions(updatedList);
    dispatch(setDraftItemsStolen(updatedList));
  };

  return (
    <div className="mb-5">
      <span className="text-sm font-bold mb-2 block">What was stolen?</span>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
        <Checkbox
          checked={props.value?.includes("cables") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Cables"
          id="cables"
          name="cables"
          value="cables"
        />
        <Checkbox
          checked={props.value?.includes("cabinet") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Cabinet"
          id="cabinet"
          name="cabinet"
          value="cabinet"
        />
        <Checkbox
          checked={props.value?.includes("battery") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Battery"
          id="battery"
          name="battery"
          value="battery"
        />
        <Checkbox
          checked={props.value?.includes("cabinet modules") ?? false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          label="Cabinet modules"
          id="cabinet_modules"
          name="cabinet_modules"
          value="cabinet modules"
        />
      </div>
    </div>
  );
};

export default ItemsStolenCheckbox;
