import React, { useEffect, useState } from "react";
import DateTimePicker from "../shared/DateTimePicker";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDraftIncidentTime } from "../../store/breakIn/breakInDraft";

interface IncidentTimeProps {
  value?: string;
}

const IncidentTime: React.FC<IncidentTimeProps> = (props) => {
  const dispatch = useAppDispatch();
  const [incidentTime, setIncidentTime] = useState<string>(props.value ?? "");
  const errors = useAppSelector(
    (state) => state.breakInDraft.errors.incidentTime
  );

  useEffect(() => {
    dispatch(setDraftIncidentTime(incidentTime));
  }, [incidentTime]);

  return (
    <div className="mb-5">
      <label htmlFor="arrivedAt" className="text-sm font-bold">
        Incident Time
      </label>
      <DateTimePicker
        onChange={(value: string) => setIncidentTime(value)}
        value={incidentTime}
      />
      {errors.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default IncidentTime;
