export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT ?? '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? '', 
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT ?? '',
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
 scopes: ["User.Read"]
};