import { createSlice } from "@reduxjs/toolkit";

interface authState {
  msToken: string | null,
  token: string | null,
  email: string | null,
}

const initialState: authState = {
  msToken: null,
  token: null,
  email: null,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMSToken: (state, action: {payload: string}) => {
      state.msToken = action.payload
    },
    setToken: (state, action: {payload: string}) => {
      state.token = action.payload
    },
    setEmail: (state, action: {payload: string}) => {
      state.email = action.payload
    },
  },
})

export const {setToken, setEmail, setMSToken} = auth.actions

export default auth.reducer
