import React from "react";
import dayjs from "dayjs";
import BreakIn from "../../models/BreakIn";
import { Link } from "react-router-dom";

export interface BreakInCardProps {
  breakin: BreakIn;
}

const BreakInCard: React.FC<BreakInCardProps> = ({ breakin }) => {
  return (
    <div className="my-3 p-3 border border-gray-700 bg-gray-800 rounded flex justify-between">
      <div>
        <div>
          <b>Site name:</b> {breakin.siteName}
        </div>
        <div>
          <b>Time:</b>{" "}
          {dayjs(breakin.incidentTime).format("dddd DD/MM/YYYY HH:mm A")}
        </div>
        <div>
          <b>Case Number:</b> {breakin.caseNumber}
        </div>
      </div>
      <div className="mt-3">
        <Link
          to={`/break-in-report/${breakin.id}`}
          className="button inline-block"
        >
          Edit
        </Link>
      </div>
    </div>
  );
};

export default BreakInCard;
