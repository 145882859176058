import React from "react";
import { useMsal } from "@azure/msal-react";

export const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <a
      className="button flex items-center justify-center w-full hover:bg-dark-600 px-3 py-2 hover:cursor-pointer"
      onClick={() => handleLogout()}
    >
      Sign Out
    </a>
  );
};
