import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDraftSiteName } from "../../store/breakIn/breakInDraft";
import SiteNameRequest from "../../requests/Autocompletes/SiteNameRequest";

interface SiteNameInputProps {
  value?: string;
  onChange?: Function;
}

interface SiteNameSuggestion {
  site_name: string;
}

const SiteNameInput: React.FC<SiteNameInputProps> = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>(props.value ?? "");
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<SiteNameSuggestion[]>([]);
  const errors: string[] = useAppSelector(
    (state) => state.breakInDraft.errors.siteName
  );

  useEffect(() => {
    dispatch(setDraftSiteName(value));
  });

  useEffect(() => {
    if (value.length > 0 && showAutocomplete) {
      SiteNameRequest(value).then((responses) => {
        setSuggestions(responses);
      });
    }
  }, [value]);

  const selectSuggestion = (selected: string) => {
    setValue(selected);
    setShowAutocomplete(false);
  };

  const closeAutocomplete = () => {
    setTimeout(() => {
      setShowAutocomplete(false);
    }, 1000);
  };

  return (
    <div className="relative">
      <label htmlFor="site_name" className="font-bold text-sm">
        Site Name
      </label>
      <input
        id="site_name"
        name="site_name"
        placeholder="Site name"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setShowAutocomplete(true)}
        onBlur={() => closeAutocomplete()}
        autoComplete="off"
      />
      {showAutocomplete == true && (
        <div className="absolute top-full mt-1 left-0 bg-white rounded border w-full shadow">
          {value.length == 0 ? (
            <p className="text-black px-3">Start typing...</p>
          ) : null}
          <ul className="text-black">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="px-3 py-1 my-1 hover:bg-blue-500 hover:text-white"
                onClick={() => selectSuggestion(suggestion.site_name)}
              >
                {suggestion.site_name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {errors.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default SiteNameInput;
