import React, { FormEvent, useEffect, useState } from "react";
import CalloutFormComments from "./CalloutFormComments";
import CalloutFormIncidentReference from "./CalloutFormIncidentReference";
import CalloutFormBatteriesStolen from "./CalloutFormBatteriesStolen";
import CalloutFormAlarms from "./CalloutFormAlarms";
import CalloutFormResponseFeedback from "./CalloutFormResponseFeedback";
import { CalloutCreateRequest } from "../../requests/Callouts/CalloutCreateRequest";
import { CalloutEditRequest } from "../../requests/Callouts/CalloutEditRequest";
import { Callout } from "../../models/Callout";
import { useNavigate } from "react-router";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import CalloutFormSiteName from "./CalloutFormSiteName";
import CalloutFormDispatchedAt from "./CalloutFormDispatchedAt";
import CalloutFormArrivedAt from "./CalloutFormArrivedAt";
import { useAppSelector } from "../../store/hooks";
import { Link } from "react-router-dom";

interface CalloutFormProps {
  callout?: Callout;
  mode: "edit" | "create";
}

const CalloutForm: React.FC<CalloutFormProps> = (props: CalloutFormProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const errors = useAppSelector((state) => state.calloutDraft.errors);
  const isInvalid = errors.arrivedAt.length != 0;

  const saveRequest =
    props.mode == "edit" ? CalloutEditRequest : CalloutCreateRequest;

  const validateForm = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    await saveRequest()
      .then(() => {
        navigate("/");
        toast.success("Callout saved successfully");
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
      });
    setIsLoading(false);
  };

  return (
    <div className="border border-gray-700 p-5 rounded bg-gray-800">
      <form action="/" method="post" onSubmit={(event) => validateForm(event)}>
        <div className="mb-3">
          <CalloutFormSiteName value={props.callout?.siteName} />
        </div>

        <div className="mb-3">
          <CalloutFormIncidentReference
            value={props.callout?.incidentReference}
          />
        </div>

        <div className="mb-3 grid gap-5 lg:grid-cols-2">
          <div>
            <CalloutFormDispatchedAt value={props.callout?.dispatchedAt} />
          </div>
          <div>
            <CalloutFormArrivedAt value={props.callout?.arrivedAt} />
          </div>
        </div>

        <CalloutFormAlarms value={props.callout?.alarms} />

        <CalloutFormResponseFeedback value={props.callout?.feedback} />

        <CalloutFormBatteriesStolen value={props.callout?.batteriesStolen} />

        <CalloutFormComments value={props.callout?.comments} />

        <div className="flex items-center">
          <button
            type="submit"
            className="button w-32 mr-5"
            disabled={isInvalid}
          >
            Save
          </button>
          {isLoading ? <Loader className="mx-3" /> : <></>}

          {props.mode === "edit" && (
            <Link
              to={`/break-in-report/new?incident=${props.callout?.incidentReference}&site_name=${props.callout?.siteName}`}
              className="button py-2 inline-block text-center"
            >
              Report Break In
            </Link>
          )}
        </div>
      </form>
    </div>
  );
};

export default CalloutForm;
