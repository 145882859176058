import { createSlice } from "@reduxjs/toolkit";
import BreakIn from "../../models/BreakIn";
import { BreakInApiErrors } from "../../models/BreakInAPIErrors";

interface breakInDraftState {
  data: BreakIn;
  errors: {
    incidentId: string[];
    siteName: string[];
    landlord: string[];
    lockType: string[];
    incidentTime: string[];
    caseNumber: string[];
    policeStation: string[];
    criminalAction: string[];
    entryType: string[];
    itemsStolen: string[];
  };
}

const initialState: breakInDraftState = {
  data: {
    id: null,
    incidentId: "",
    siteName: "",
    landlord: "",
    lockType: "",
    incidentTime: "",
    caseNumber: "",
    policeStation: "",
    criminalAction: [],
    entryType: [],
    itemsStolen: [],
  },
  errors: {
    incidentId: [],
    siteName: [],
    landlord: [],
    lockType: [],
    incidentTime: [],
    caseNumber: [],
    policeStation: [],
    criminalAction: [],
    entryType: [],
    itemsStolen: [],
  },
};

const breakInDraft = createSlice({
  name: "breakInDraft",
  initialState,
  reducers: {
    setDraftId: (state, { payload }: { payload: number }) => {
      state.data.id = payload;
    },
    setDraftIncidentId: (state, { payload }: { payload: string }) => {
      state.data.incidentId = payload;
    },
    setDraftSiteName: (state, { payload }: { payload: string }) => {
      state.data.siteName = payload;
    },
    setDraftLandlord: (state, { payload }: { payload: string }) => {
      state.data.landlord = payload;
    },
    setDraftLockType: (state, { payload }: { payload: string }) => {
      state.data.lockType = payload;
    },
    setDraftIncidentTime: (state, { payload }: { payload: string }) => {
      state.data.incidentTime = payload;
    },
    setDraftCaseNumber: (state, { payload }: { payload: string }) => {
      state.data.caseNumber = payload;
    },
    setDraftPoliceStation: (state, { payload }: { payload: string }) => {
      state.data.policeStation = payload;
    },
    setDraftCriminalAction: (state, { payload }: { payload: string[] }) => {
      state.data.criminalAction = payload;
    },
    setDraftEntryType: (state, { payload }: { payload: string[] }) => {
      state.data.entryType = payload;
    },
    setDraftItemsStolen: (state, { payload }: { payload: string[] }) => {
      state.data.itemsStolen = payload;
    },
    clearDraft: (state) => {
      state.data.incidentId = "";
      state.data.siteName = "";
      state.data.landlord = "";
      state.data.lockType = "";
      state.data.incidentTime = "";
      state.data.caseNumber = "";
      state.data.policeStation = "";
      state.data.criminalAction = [];
      state.data.entryType = [];
      state.data.itemsStolen = [];
    },
    setDraftBreakIn: (state, { payload }: { payload: BreakIn }) => {
      state.data.id = payload.id;
      state.data.incidentId = payload.incidentId;
      state.data.siteName = payload.siteName;
      state.data.landlord = payload.landlord;
      state.data.lockType = payload.lockType;
      state.data.incidentTime = payload.incidentTime;
      state.data.caseNumber = payload.caseNumber;
      state.data.policeStation = payload.policeStation;
      state.data.criminalAction = payload.criminalAction;
      state.data.entryType = payload.entryType;
      state.data.itemsStolen = payload.itemsStolen;
    },
    setDraftErrors: (
      state,
      { payload }: { payload: { errors: BreakInApiErrors } }
    ) => {
      state.errors.incidentId = payload.errors.incident_id ?? [];
      state.errors.siteName = payload.errors.site_name ?? [];
      state.errors.landlord = payload.errors.landlord ?? [];
      state.errors.lockType = payload.errors.locktype ?? [];
      state.errors.incidentTime = payload.errors.occurred_at ?? [];
      state.errors.caseNumber = payload.errors.case_number ?? [];
      state.errors.policeStation = payload.errors.police_station ?? [];
    },
    setDraftErrorCaseNumber: (state, { payload }: { payload: string[] }) => {
      state.errors.caseNumber = payload;
    },
    setDraftErrorPoliceStation: (state, { payload }: { payload: string[] }) => {
      state.errors.policeStation = payload;
    },
  },
});

export const {
  setDraftId,
  setDraftIncidentId,
  setDraftSiteName,
  setDraftLandlord,
  setDraftLockType,
  setDraftIncidentTime,
  setDraftCaseNumber,
  setDraftPoliceStation,
  setDraftCriminalAction,
  setDraftEntryType,
  setDraftItemsStolen,
  clearDraft,
  setDraftBreakIn,
  setDraftErrors,
  setDraftErrorCaseNumber,
  setDraftErrorPoliceStation,
} = breakInDraft.actions;

export default breakInDraft.reducer;
