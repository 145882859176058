import React, { ChangeEvent, useState } from "react"

interface CheckboxProps {
  checked: boolean
  onChange: Function
  label: string
  name: string
  value: string
  id: string
  disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(props.checked ?? false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    props.onChange(event)
  }

  return (
    <div className="flex items-center">
      <input 
        name={props.name}
        id={props.id}
        type="checkbox"
        className="hidden"
        value={props.value}
        onChange={(e) => handleChange(e)}
        checked={checked}
        disabled={props.disabled ?? false}
      />

      <label 
        htmlFor={props.id}
        className={`text-sm flex items-center ${props.disabled ? 'text-gray-300 cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <div className={`checkbox-container ${props.disabled ? 'disabled' : null}`}>
          <div className={`checkbox-checkmark ${checked ? 'checked': null}`}></div>
        </div>
        {props.label}
      </label>
    </div>
  )
}

export default Checkbox