import store from "../../store";
import {
  clearDraft,
  setDraftIncidentId,
  setDraftSiteName,
} from "../../store/breakIn/breakInDraft";

export const newBreakInLoader = (request: Request) => {
  store.dispatch(clearDraft());
  const url = new URL(request.url);
  const siteName = url.searchParams.get("site_name") ?? "";
  const incidentId = url.searchParams.get("incident") ?? "";
  store.dispatch(setDraftSiteName(siteName));
  store.dispatch(setDraftIncidentId(incidentId));
  return true;
};
