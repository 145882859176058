import { createSlice } from "@reduxjs/toolkit";
import { OpenCalloutOption } from "../../models/OpenCalloutOption";

interface OpenCalloutsState {
  options: OpenCalloutOption[]
}

const initialState: OpenCalloutsState = {
  options: []
}

const autocompletes = createSlice({
  name: 'openCalloutOptions',
  initialState,
  reducers: {
    syncOpenCallouts: (state, {payload}: {payload: OpenCalloutOption[]}) => {
      const stateWithNewOptions = state.options.concat(
        payload.filter(
          (option) => !state.options.includes(option)
        )
      )
      
      state.options = stateWithNewOptions.filter(
        (option) => payload.includes(option)
      )
    },
  },
})

export const { 
  syncOpenCallouts
} = autocompletes.actions

export default autocompletes.reducer
