import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDraftLockType } from "../../store/breakIn/breakInDraft";

interface LocktypeProps {
  value?: string;
  onChange?: Function;
}

const LocktypeInput: React.FC<LocktypeProps> = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(props.value ?? "");
  const errors = useAppSelector((state) => state.breakInDraft.errors.lockType);

  useEffect(() => {
    dispatch(setDraftLockType(value));
  });

  return (
    <div>
      <label htmlFor="site_name" className="font-bold text-sm">
        Lock Type
      </label>
      <input
        id="locktype"
        name="locktype"
        placeholder="Locktype"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {errors.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default LocktypeInput;
