import React from "react"
import CalloutsList from "../../components/calloutsList/CalloutsList"

const RecentlyClosedView: React.FC = () => {
  return (
    <div>
      <h1 className="font-bold text-2xl">Recent feedback</h1>

      <CalloutsList />
    </div>
  )
}

export default RecentlyClosedView