import React, { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router"
import MainNavigation from "../components/navigation/MainNavigation"
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { setEmail } from "../store/auth/auth";
import { AuthTokenCreateRequest } from "../requests/AuthTokenCreateRequest";

const GuestLayout: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const token = useAppSelector<string|null>(state => state.auth.token)
  const { inProgress, accounts } = useMsal();

  useEffect(() => {
    if(token){
      navigate('/')
    }
    if(accounts.length && !token) {
      dispatch(setEmail(accounts[0].username))
      AuthTokenCreateRequest({email: accounts[0].username})
    }
  }, [token])

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <MainNavigation />
      
      <UnauthenticatedTemplate>
      <div className="container mx-auto py-12">
        <Outlet />
      </div>
      </UnauthenticatedTemplate>

      <ToastContainer />
    </div>
  )
}

export default GuestLayout