import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppSelector } from "../store/hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthenticatedTemplate } from "@azure/msal-react";
import SideNavigation from "../components/navigation/SideNavigation";
import MainNavigation from "../components/navigation/MainNavigation";

const AppLayout: React.FC = () => {
  const msToken = useAppSelector<string | null>((state) => state.auth.msToken);
  const token = useAppSelector<string | null>((state) => state.auth.token);

  if (!msToken && !token) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  return (
    <div className="h-screen bg-gray-900 text-white overflow-hidden">
      <div className="relative">
        <SideNavigation />

        <main className="w-full h-screen overflow-y-scroll">
          <MainNavigation />
          <AuthenticatedTemplate>
            <div className="w-full mx-auto py-8 px-5">
              <Outlet />
            </div>
          </AuthenticatedTemplate>
        </main>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AppLayout;
