import React from "react";
import { useAppSelector } from "../../store/hooks";
import CalloutsShowRequest from "../../requests/Callouts/CalloutsShowRequest";

const CalloutsListPaginator: React.FC = () => {
  const currentPage = useAppSelector(
    (state) => state.calloutsList.pagination?.current_page
  );
  const lastPage = useAppSelector(
    (state) => state.calloutsList.pagination?.last_page
  );

  const nextPage = () => {
    CalloutsShowRequest({ page: currentPage! + 1 });
  };

  const previousPage = () => {
    CalloutsShowRequest({ page: currentPage! - 1 });
  };

  return (
    <ul className="flex justify-center">
      {currentPage !== 1 && (
        <li
          className="mx-3 hover:cursor-pointer text-blue-500"
          onClick={() => previousPage()}
        >
          Previous
        </li>
      )}

      <li className="mx-3 text-gray-300">{currentPage}</li>

      {currentPage !== lastPage && (
        <li
          className="mx-3 hover:cursor-pointer text-blue-500"
          onClick={() => nextPage()}
        >
          Next
        </li>
      )}
    </ul>
  );
};

export default CalloutsListPaginator;
