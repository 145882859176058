import adtBe from "../config/adtBe.config"
import { TokenCredentials } from "../models/TokenCredentials"
import store from "../store"
import { setToken } from "../store/auth/auth"

export const AuthTokenCreateRequest = ({email}: TokenCredentials) => {
  return new Promise((resolve, reject) => {
    adtBe.post('/auth/token', {
      email, 
    })
    .then((response) => {
      adtBe.defaults.headers.common['Authorization'] = `Bearer ${response.data?.data?.token}`
      document.cookie = JSON.stringify({authToken:response.data?.data?.token})
      store.dispatch(setToken(response.data?.data?.token))
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    })
  })
}
