interface OpenCalloutRequestHeaders {
  headers: {
    Accept: string,
    apikey?: string
  }
}

export const createHeaders = () => {
  if(process.env.REACT_APP_ENV == 'local') {
    return {
      headers: {
        Accept: 'application/json',
      }
    }
  }

  return {
    headers: {
      Accept: 'application/json',
      apikey: process.env.REACT_APP_OPEN_CALLOUTS_KEY
    }
  }
}

