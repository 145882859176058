import React, { useEffect, useState } from "react";
import { setDraftIncidentId } from "../../store/breakIn/breakInDraft";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface IncidentIDInputProps {
  value?: string;
  onChange?: Function;
}

const IncidentIDInput: React.FC<IncidentIDInputProps> = (props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>(props.value ?? "");
  const errors = useAppSelector(
    (state) => state.breakInDraft.errors.incidentId
  );

  useEffect(() => {
    dispatch(setDraftIncidentId(value));
  }, [value]);

  return (
    <div>
      <label htmlFor="incident_id" className="font-bold text-sm">
        Incident ID
      </label>
      <input
        id="incident_id"
        name="incident_id"
        placeholder="Incident ID"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={errors.length ? "hasError" : ""}
      />
      {errors?.map((error, index) => (
        <div key={index} className="text-red-500 text-sm">
          {error}
        </div>
      ))}
    </div>
  );
};

export default IncidentIDInput;
