import React, { useEffect, useState } from "react";
import BreakInsShowRequest from "../../requests/BreakIns/BreakInsShowRequest";
import Loader from "../../components/loader/Loader";
import { useAppSelector } from "../../store/hooks";
import BreakIn from "../../models/BreakIn";
import BreakInCard from "../../components/breakInsList/BreakInCard";
import BreakInListPaginator from "../../components/breakInsList/BreakInListPaginator";
import BreakInSearchFilters from "../../components/breakInSearchFilters/BreakInSearchFilters";

const BreakInsListView: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const breakins: BreakIn[] = useAppSelector(
    (state) => state.breakInsList.data
  );

  const fetchBreakIns = async () => {
    setLoading(true);
    await BreakInsShowRequest();
    setLoading(false);
  };

  useEffect(() => {
    fetchBreakIns();
  }, []);

  return (
    <div>
      <h1 className="font-bold text-2xl">Recently reported break ins</h1>

      <BreakInSearchFilters />

      {!loading && !breakins.length ? (
        <div className="p-3 bg-gray-800 border border-gray-700 rounded">
          No results found
        </div>
      ) : (
        <></>
      )}

      {loading ? (
        <div className="my-3 flex justify-center">
          <Loader width={50} height={50} />
        </div>
      ) : (
        <></>
      )}

      {breakins.map((breakin: BreakIn) => (
        <BreakInCard key={breakin.id} breakin={breakin} />
      ))}

      {breakins.length ? <BreakInListPaginator /> : <></>}
    </div>
  );
};

export default BreakInsListView;
