import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store'
import './index.css';
import router from './router';
import { RouterProvider } from 'react-router';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msal.config";
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider>
  </MsalProvider>
);
