import React from "react"
import { SignInButton } from "../../components/auth/SignInButton"

const LoginView: React.FC = () => {
  return (
    <div 
      className="max-w-md mx-auto p-5 border border-gray-700 bg-gray-800 rounded"
    >
      <h1 className="text-2xl font-bold text-center mb-5 border-b border-gray-700 pb-5">
        Login
      </h1>

      <div className="flex justify-center items-center">
        <SignInButton />
      </div>
    </div>
  )
}

export default LoginView