import React, { FormEvent, useEffect, useState } from "react";
import Loader from "../loader/Loader";
import CalloutsShowRequest from "../../requests/Callouts/CalloutsShowRequest";
import {
  setIncidentIdFilter,
  setSiteNameFilter,
} from "../../store/callout/calloutsList";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const CalloutsListFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector((state) => state.calloutsList.searchFilters);
  const currentPage = useAppSelector(
    (state) => state.calloutsList.pagination?.current_page
  );
  const [siteName, setSiteName] = useState<string>("");
  const [incidentId, setIncidentId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setSiteNameFilter(siteName));
  }, [siteName]);

  useEffect(() => {
    dispatch(setIncidentIdFilter(incidentId));
  }, [incidentId]);

  const search = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    await CalloutsShowRequest({ page: currentPage, ...params });
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={(e) => search(e)}
      className="my-5 p-5 bg-gray-800 border border-gray-700 rounded"
    >
      <h3 className="font-bold pb-3 mb-3 text-xl border-b-4 border-gray-600">
        Search
      </h3>
      <div className="flex items-end">
        <div className="mr-3 w-1/6">
          <label className="text-sm font-bold">Incident ID</label>
          <input
            onChange={(e) => setIncidentId(e.target.value)}
            value={incidentId}
          />
        </div>

        <div className="mr-3 w-full">
          <label className="text-sm font-bold">Site name</label>
          <input
            onChange={(e) => setSiteName(e.target.value)}
            value={siteName}
          />
        </div>

        <div className="w-auto">
          <button type="submit" className="button flex items-center">
            {isLoading && <Loader color="#0F172A" className="mx-3" />}
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default CalloutsListFilters;
