import React, { ChangeEvent, useEffect, useState } from "react"
import { responseFeedbackOptions } from "../../config/formOptions.config"
import { FormOption } from "../../models/FormOption"
import { useAppDispatch } from "../../store/hooks"
import { setDraftFeedback } from "../../store/callout/calloutDraft"

interface feedbackProps {
  value?: string
}

const CalloutFormResponseFeedback: React.FC<feedbackProps> = (props: feedbackProps) => {
  const dispatch  = useAppDispatch()
  const [feedback, setFeedback] = useState<string>(props.value?.length ? String(props.value) : 'all in order' )

  const setAndDispatch = (event: ChangeEvent<HTMLSelectElement>) => {
    setFeedback(event.target.value)
  }
  
  useEffect(() => {
    dispatch(setDraftFeedback(feedback))
  }, [feedback])

  return (
    <div className="mb-3">
      <label 
        htmlFor="responseFeedback"
        className="text-sm font-bold"
      >
        Feedback
      </label>
      <select 
        id="responseFeedback"
        onChange={setAndDispatch}
        defaultValue={feedback}
      >
        {
          responseFeedbackOptions.map((option: FormOption, index: number) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))
        }
      </select>
    </div>
  )
}

export default CalloutFormResponseFeedback