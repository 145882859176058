import { createSlice } from "@reduxjs/toolkit";
import { Callout } from "../../models/Callout";
import { OpenCalloutOption } from "../../models/OpenCalloutOption";

interface calloutDraftState {
  data: Callout;
  errors: {
    arrivedAt: string[];
  };
}

const initialState: calloutDraftState = {
  data: {
    siteName: "",
    incidentReference: "",
    alarms: [],
    dispatchedAt: "",
    arrivedAt: "",
    feedback: "",
    batteriesStolen: "no",
    comments: "",
    closed: true,
  },
  errors: {
    arrivedAt: [],
  },
};

const calloutDraft = createSlice({
  name: "calloutDraft",
  initialState,
  reducers: {
    setDraftCallout: (state, action: { payload: Callout }) => {
      state.data = action.payload;
    },
    setDraftSiteName: (state, action: { payload: string }) => {
      state.data.siteName = action.payload;
    },
    setDraftIncidentReference: (state, action: { payload: string }) => {
      state.data.incidentReference = action.payload;
    },
    setDraftAlarms: (state, action: { payload: string[] }) => {
      state.data.alarms = action.payload;
    },
    setDraftDispatchedAt: (state, action: { payload: string }) => {
      state.data.dispatchedAt = action.payload;
    },
    setDraftArrivedAt: (state, action: { payload: string }) => {
      state.data.arrivedAt = action.payload;
    },
    setDraftFeedback: (state, action: { payload: string }) => {
      state.data.feedback = action.payload;
    },
    setDraftBatteriesStolen: (state, action: { payload: string }) => {
      state.data.batteriesStolen = action.payload;
    },
    setDraftComments: (state, action: { payload: string }) => {
      state.data.comments = action.payload;
    },
    setDraftClosed: (state, action: { payload: boolean }) => {
      state.data.closed = action.payload;
    },
    resetDraftCallout: (state) => {
      (state.data.siteName = ""), (state.data.incidentReference = "");
      (state.data.alarms = []),
        (state.data.dispatchedAt = ""),
        (state.data.arrivedAt = ""),
        (state.data.feedback = ""),
        (state.data.batteriesStolen = "no"),
        (state.data.comments = ""),
        (state.data.closed = false);
    },
    autoFillDraft: (state, action: { payload: OpenCalloutOption }) => {
      state.data.siteName = action.payload?.site_name;
      state.data.incidentReference = String(action.payload?.incident_id);
      state.data.dispatchedAt = action.payload?.dispatched_at;
      state.data.alarms = action.payload?.alarms.map((alarm) => {
        if (alarm === "water") {
          alarm = "vibration";
        }
        return alarm.toLowerCase();
      });
    },
    setDraftArrivedAtErrors: (state, { payload }: { payload: string[] }) => {
      state.errors.arrivedAt = payload;
    },
  },
});

export const {
  setDraftCallout,
  setDraftIncidentReference,
  setDraftSiteName,
  setDraftAlarms,
  setDraftDispatchedAt,
  setDraftArrivedAt,
  setDraftFeedback,
  setDraftBatteriesStolen,
  setDraftComments,
  setDraftClosed,
  resetDraftCallout,
  autoFillDraft,
  setDraftArrivedAtErrors,
} = calloutDraft.actions;

export default calloutDraft.reducer;
