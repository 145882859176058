import React, { FormEvent, useState } from "react";
import SiteNameInput from "./SiteNameInput";
import IncidentIDInput from "./IncidentIDInput";
import PoliceCaseNumberInput from "./PoliceCaseNumberInput";
import PoliceStationInput from "./PoliceStationInput";
import CriminalActionCheckbox from "./CriminalActionCheckbox";
import EntryTypeCheckbox from "./EntryTypeCheckbox";
import ItemsStolenCheckbox from "./ItemsStolenCheckbox";
import IncidentTime from "./IncidentTime";
import LandlordInput from "./LandlordInput";
import LocktypeInput from "./LocktypeInput";
import Loader from "../loader/Loader";
import BreakInCreateRequest from "../../requests/BreakIns/BreakInCreateRequest";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router";
import BreakInEditRequest from "../../requests/BreakIns/BreakinEditRequest";
import { setDraftErrors } from "../../store/breakIn/breakInDraft";

interface BreakInReportFormProps {
  mode: "edit" | "create";
}

const BreakInReportForm: React.FC<BreakInReportFormProps> = ({ mode }) => {
  const draftBreakIn = useAppSelector((state) => state.breakInDraft.data);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const isInvalid = useAppSelector<boolean>((state) => {
    return (
      state.breakInDraft.errors.policeStation.length == 0 &&
      state.breakInDraft.errors.caseNumber.length == 0 &&
      state.breakInDraft.errors.siteName.length == 0 &&
      state.breakInDraft.errors.landlord.length == 0 &&
      state.breakInDraft.errors.incidentId.length == 0 &&
      state.breakInDraft.data.siteName.length > 0 &&
      state.breakInDraft.data.landlord.length > 0
    );
  });

  const request = mode == "edit" ? BreakInEditRequest : BreakInCreateRequest;

  const submit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    request()
      .then(() => {
        toast.success("Break in reported");
        navigate("/break-in-reports");
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        dispatch(setDraftErrors({ errors: error.response?.data?.errors }));
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={(event) => submit(event)}>
      <h1 className="text-2xl font-bold mb-6">Report a break in</h1>
      <div className="bg-gray-800 border border-gray-700 p-5 rounded my-3 w-full max-w-6xl">
        <div className="grid gap-5 lg:grid-cols-2">
          <IncidentIDInput value={draftBreakIn.incidentId} />

          <SiteNameInput value={draftBreakIn.siteName} />

          <LandlordInput value={draftBreakIn.landlord} />

          <LocktypeInput value={draftBreakIn.lockType} />

          <IncidentTime value={draftBreakIn.incidentTime} />
        </div>

        <div className="grid gap-5 lg:grid-cols-2 mb-5">
          <PoliceCaseNumberInput value={draftBreakIn.caseNumber} />
          <PoliceStationInput value={draftBreakIn.policeStation} />
        </div>

        <CriminalActionCheckbox value={draftBreakIn.criminalAction} />

        <EntryTypeCheckbox value={draftBreakIn.entryType} />

        <ItemsStolenCheckbox value={draftBreakIn.itemsStolen} />

        <div>
          <button className={`button w-32`} disabled={!isInvalid}>
            Save
          </button>
          {isLoading ? <Loader className="mx-3" /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default BreakInReportForm;
