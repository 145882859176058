import React from "react";
import { Link } from "react-router-dom";
import { OpenCalloutOption } from "../../models/OpenCalloutOption";
import { useAppDispatch } from "../../store/hooks";
import { autoFillDraft } from "../../store/callout/calloutDraft";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface OpenCalloutListItemProps {
  callout: OpenCalloutOption;
}

const OpenCalloutListItem: React.FC<OpenCalloutListItemProps> = ({
  callout,
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="border border-gray-700 bg-gray-800 p-3 rounded overflow-hidden">
      <div className="break-words inline-block w-full mb-3">
        <div className="font-bold mr-1 block lg:inline-block text-sm">
          Site Name:
        </div>
        <div>{callout.site_name}</div>
      </div>

      <div className="flex justify-between w-full">
        <div className="mb-3 w-full">
          <span className="block text-sm font-bold">Incident: </span>
          <span className="block">#{callout.incident_id}</span>
        </div>

        <div className="text-right w-full mb-3">
          <div className="font-bold mr-1 block lg:inline-block text-sm">
            Dispatched at:
          </div>
          <div>{dayjs(callout.dispatched_at).format("HH:mm DD-MM-YYYY")}</div>
          <div>{dayjs(callout.dispatched_at).fromNow(false)}</div>
        </div>
      </div>

      <div className="grid gap-5 grid-cols-2">
        <Link
          to={`/callout/new?incident=${callout.incident_id}`}
          className="button px-3 inline-block w-auto text-center shadow shadow-black"
          onClick={() => dispatch(autoFillDraft(callout))}
        >
          Report Feedback
        </Link>
      </div>
    </div>
  );
};

export default OpenCalloutListItem;
