import { Params } from "react-router";
import BreakIn from "../../models/BreakIn";
import store from "../../store";
import { setDraftBreakIn } from "../../store/breakIn/breakInDraft";
import { redirect } from "react-router-dom";

export const editBreakInLoader = (params: Params) => {
  if (!store.getState().breakInsList.data.length) {
    redirect("/break-in-reports");
    return null;
  }

  const draftBreakIn: BreakIn[] = store
    .getState()
    .breakInsList.data.filter((breakIn) => breakIn.id == params.id);

  store.dispatch(setDraftBreakIn(draftBreakIn[0]));
  return draftBreakIn;
};
