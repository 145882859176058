import React from "react";
import CalloutForm from "../../components/calloutForm/CalloutForm";
import { useAppSelector } from "../../store/hooks";

const CalloutCreateView: React.FC = () => {
  const draftCallout = useAppSelector(state => state.calloutDraft.data)

  return (
    <div>
      <h1 className="font-bold text-2xl mb-5">Report callout feedback</h1>
      <CalloutForm mode="create" callout={draftCallout}/>
    </div>
  )
}

export default CalloutCreateView;
