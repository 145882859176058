import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setDraftIncidentReference } from '../../store/callout/calloutDraft'

interface IncidentReferenceProps {
  value?: string
}

const CalloutFormIncidentReference: React.FC<IncidentReferenceProps> = (props: IncidentReferenceProps) => {
  const dispatch = useAppDispatch()
  const [incidentReference, setIncidentReference] = useState<string>(props.value ?? '')

  const validateAndDispatch = () => {
    dispatch(setDraftIncidentReference(incidentReference))
  }

  return (
    <div className="mb-3">
      <label 
        htmlFor="incidentReference"
        className="text-sm font-bold"
      >
        Incident Reference
      </label>
      <input 
        id="incidentReference"
        onChange={e => setIncidentReference(e.target.value)}
        onBlur={() => validateAndDispatch()}
        value={incidentReference}
        placeholder='Incident Reference Number'
        disabled
      />
    </div>
  )
}

export default CalloutFormIncidentReference