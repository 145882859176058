import React from "react"

const NoMatchView : React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-5">404</h1>
        <div>
          Could not find a route matching the current path.
        </div>
      </div>
    </div>
  )
}

export default NoMatchView