import React, { useEffect, useRef, useState } from "react"
import DateTimePicker from "../shared/DateTimePicker"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setDraftDispatchedAt } from "../../store/callout/calloutDraft"

interface DispatchedAtProps {
  value?: string
}

const CalloutFormDispatchedAt: React.FC<DispatchedAtProps> = (props: DispatchedAtProps) => {
  const dispatch = useAppDispatch()
  const [dispatchedAt, setDispatchedAt] = useState<string>(props.value ?? '')
  const render = useRef(0)

  const validateAndDispatch = (value: string) => {
    dispatch(setDraftDispatchedAt(value))
  }

  return (
    <div>
      <label 
        htmlFor="arrivedAt"
        className="text-sm font-bold"
      >
        Dispatched at
      </label>
      <DateTimePicker 
        key={render.current}
        onChange={(value: string) => validateAndDispatch(value)}
        value={dispatchedAt}
        disabled={props.value ? true : false}
      />
    </div>
  )
}

export default CalloutFormDispatchedAt