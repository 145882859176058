import React, { useEffect, useState } from "react"
import { useAppDispatch } from "../../store/hooks"
import { setDraftBatteriesStolen } from "../../store/callout/calloutDraft"

interface BatteriesStolenProps {
  value?: string
}

const CalloutFormBatteriesStolen: React.FC<BatteriesStolenProps> = (props: BatteriesStolenProps) => {
  const dispatch = useAppDispatch()
  const [batteriesStolen, setBatteriesStolen] = useState<string>(props.value ?? 'no')

  useEffect(() => {
    dispatch(setDraftBatteriesStolen(batteriesStolen))
  }, [batteriesStolen])

  return (
    <div className="mb-3">
      <span className="font-bold text-sm">Were the batteries stolen?</span>
      <div>
        <select 
          onChange={(e) => setBatteriesStolen(e.target.value)}
          className="w-full"
        >
          <option value="no">No</option>
          <option value="not sure">Not sure</option>
          <option value="yes">Yes</option>
        </select>
      </div>
    </div>
  )
}

export default CalloutFormBatteriesStolen