import { configureStore } from "@reduxjs/toolkit";
import calloutDraft from "./callout/calloutDraft";
import calloutsList from "./callout/calloutsList";
import openCalloutOptions from "./callout/openCalloutOptions";
import breakInDraft from "./breakIn/breakInDraft";
import breakInsList from "./breakIn/breakInsList";
import menu from "./menu/menu";
import auth from "./auth/auth";

const store = configureStore({
  reducer: {
    auth,
    menu,
    breakInDraft,
    breakInsList,
    calloutDraft,
    calloutsList,
    openCalloutOptions,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
